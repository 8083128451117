import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

const Support = () => {
    return (
        <div className='App'>
            <Container >
                <Row className='Support' id='Support'>
                </Row>
                <Col className='add'>
                    <IoLocationSharp />
                    <h5><strong>Our Location</strong></h5>
                    <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3428.946096012725!2d76.6392922!3d30.7480156!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ffb6f25201e87%3A0x473761cb1a9bdc38!2sIron%20and%20Wash!5e0!3m2!1sen!2sin!4v1706445474648!5m2!1sen!2sin" title="Map Marker"></iframe>
                    <p className='title-add'><b>SCO 2, JANTA CHOWNK, KHARAR, PUNJAB 140301</b></p>
                </Col>
            
                    <Col className='Support-call'>
                        <h6>Support Helpline (Thursday Closed)</h6>
                        <IoCall /> <a href="tel:8264145099">8264145099 </a>

                    </Col>
                    <Col className='Support-mail'>
                    
                        <h6>Email Address</h6>
                        <MdEmail /><a href="mailto: ironandwash@gmail.com"> ironandwash@gmail.com</a>
                    </Col>
             
                
            </Container><br />
            <div >
            </div><br />
            <div className="footer__copyright">
                
                <small>&copy; ironandwash.com</small>
            </div>
        </div>
    )
}

export default Support