import React from 'react'
import ImageSlider from './ImageSlider';
import pic1 from '../Assets/pic1.jpg';
import pic2 from '../Assets/pic2.jpg';
import pic3 from '../Assets/pic3.jpg';
import './Style.css'
import { Container, Nav, Navbar} from 'react-bootstrap'
import logo from '../Assets/logo.png'

const Navigation = () => {
    const sliderImages = [pic1, pic2, pic3];
    return (
        <div >
            <Navbar bg="dark" className='nav'variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />&nbsp;&nbsp;
                        <strong>Iron and Wash </strong>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/"><strong>Home</strong></Nav.Link>
                            <Nav.Link href="/"><strong>Pricing</strong></Nav.Link>
                            <Nav.Link href="#Support"><strong>Support</strong></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                        

                </Container>
                </Navbar>
                <Container id='slideshow-container'>
                <ImageSlider images={sliderImages} interval={3000} />

            </Container>
           
            <a href="https://www.google.co.in/maps/dir//SCO+6,+Iron+and+Wash,+Jain+Complex,+opposite+HDFC+bank,+Gobind+Colony,+Garden+Colony,+Kharar,+Punjab+140301/@30.7480156,76.6418671,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x390ffb6f25201e87:0x473761cb1a9bdc38!2m2!1d76.6418671!2d30.7480156!3e0?entry=ttu">
                <button className="shop-visit-button">CLICK TO VISIT OUR OFFLINE STORE (Thursday Closed)</button>
            </a>

            
        </div>
    )
}

export default Navigation