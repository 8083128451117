import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <h2>Terms and Conditions</h2>
      <ol>
        <li>The above-mentioned details are correct, and I agree to the terms and conditions by giving my clothes.</li>
        <li>I have reviewed the prices on the website and will be charged accordingly.</li>
        <li>Delivery charges will be applied if the criteria for free delivery are not met, and I have read these criteria.</li>
        <li>Delivery charges will be charged as ₹350-(order value).</li>
        <li>Clothes will be delivered in 3 days, but in some situations like heavy rainfall, electricity cuts, water supply cuts, national holidays, natural disasters, man-made disasters, delivery may take longer.</li>
        <li>If I don't pay the exact amount on the bill, legal action can be taken against me.</li>
        <li>In the case of lost or damaged items, a solution will be provided within 10 days.</li>
        <li>Any complaint should be informed within 48 hours of delivery else we are not responsible.</li>
        <li>In the case of lost and damaged items, the store will provide half the amount of the clothing's worth of laundry service.</li>
        <li>The store will not be accountable for any color bleeding laundry.</li>
        <li>If any order weighing less than 1kg will be charged as 1kg.</li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
